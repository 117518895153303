<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ $tc('feature', 2) }}</span>
      <router-link v-if="userReadOnly" class="card-header-button el-button el-button--primary el-button--mini" style="float:right" tag="button" to="/features/create">
        <i class="fad fa-plus"></i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <br>
    <el-form :inline="true">
      <el-form-item label="Servicio">
        <select-service v-model="serviceId" @set-selected="search"></select-service>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">
          Buscar
        </el-button>
      </el-form-item>
    </el-form>
    <span>
      Registros: {{ features.length }}
    </span>
    <el-table v-loading="loading" style="width: 100%" :data="features" stripe border>
      <el-table-column prop="id" sortable label="ID" :width="60"></el-table-column>
      <el-table-column :width="40" align="center">
        <template slot-scope="scope">
          <i v-if="scope.row.featureIcon" :class="'far ' + scope.row.featureIcon"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('status')" align="center" :width="110">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.featureEnabled" :content="$t('enabled')" placement="right">
            <span>
              <i class="fas fa-circle text-success"></i>
            </span>
          </el-tooltip>
          <el-tooltip v-else :content="$t('disabled')" placement="right">
            <span>
              <i class="fas fa-circle text-danger"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="featureName" sortable :label="$tc('name', 1)"></el-table-column>
      <el-table-column :label="$tc('type', 1)" :width="120">
        <template slot-scope="scope">
          {{ $t(scope.row.featureType) }}
        </template>
      </el-table-column>
      <el-table-column prop="serviceName" :label="$t('service')"></el-table-column>
      <el-table-column prop="featureUnitPriceCustom" sortable :label="$tc('price', 1)"></el-table-column>
      <el-table-column label="Controlado por Plan/Paquete" align="center" :width="115">
        <template slot-scope="scope">
          <span v-if="scope.row.featureIsControlled">
            <i class="fad fa-check-circle text-success"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('isVisible')" align="center" :width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.featureIsVisible">
            <i class="fad fa-check-circle text-success"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="userReadOnly" :width="110" align="center" :label="$tc('action', 2)" fixed="right">
        <template slot-scope="scope">
          <el-button-group size="mini">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click.prevent="update(scope.row.id)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click.prevent="remove(scope.row.id, scope.$index)"></el-button>
            </el-button-group>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import { i18n } from '../../i18n'

import router from '../../router'

export default {
  data() {
    return {
      features: [],
      loading: false,
      serviceId: 1,
    }
  },
  computed: {
    roleId() {
      return JSON.parse(localStorage.getItem('masters')).roleId
    },
    userReadOnly() {
      return this.roleId.localeCompare('1') === 0
    },
  },
  mounted() {
    this.search()
  },
  methods:
    {
      search() {
        const _this = this
        _this.features = []
        if (_this.serviceId) {
          _this.loading = true
          _this.$http.get(`features/findByService/${_this.serviceId}`).then(response => {
            _this.features = response.body
            _this.loading = false
          }, response => {
            _this.loading = false
          })
        } else {
          _this.$message({ type: 'error', message: 'Seleccione un servicio!' })
        }
      },
      update(id) {
        router.push({ name: 'FeatureUpdate', params: { id } })
      },
      remove(id, index) {
        const _this = this
        _this.$confirm(i18n.t('irreversibleProcess'), 'Warning', {
          confirmButtonText: i18n.t('yes'),
          cancelButtonText: i18n.t('no'),
          type: 'warning',
        }).then(() => {
          _this.loading = true
          _this.$http.delete(`features/${id}`).then(response => {
            _this.$message({ type: 'success', message: i18n.t('processSuccess') })
            _this.features.splice(index, 1)
            _this.loading = false
          }, response => {
            _this.loading = false
          })
        })
      },
    },
}
</script>
